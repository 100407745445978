<template>
  <div class="home">
    <div class="cont">
      <ul>
        <li v-for="(item, index) in dataList" :key="item.id">
          <h4>{{item.typeName}}</h4>
          <van-grid :border="false" square>
            <van-grid-item v-for="item2 in item.pageList" :key="item2.id" @click="clickFn(item2.pageId)">
              <div :class="`form-color ${item2.iconColor}`">
                <i :class="`form-icon ${item2.icon}`"></i>
              </div>
              <p style="text-align:center;">{{item2.title}}</p>
            </van-grid-item>
          </van-grid>
          <div class="border" v-if="index < dataList.length - 1"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: 1,
      limit: 1,
      dataList: []
    }
  },
  methods: {
    init () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/customize/customizepagetype/list'),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    clickFn (id) {
      this.$router.push({path: '/formList', query: {id: id}})
    }
  },
  created () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-grid-item__content {
  padding: 0;
}
.home {
  .fr {
    float: right !important;
  }
  .cont {
    overflow: hidden;
    // padding: 40px;
    background-color: #fff;
    ul {
      width: 100%;
      li {
        h4 {
          font-size: 30px;
          margin: 30px 44px;
          // margin-bottom: 30px;
        }
        .form-color {
          border-radius: 20px;
          position: relative;
          width: 100px;
          height: 100px;
        }
        .form-icon {
          color: #fff;
          font-size: 58px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          font-size: 26px;
        }
      }
    }
  }
}
.red {
  background-image: linear-gradient(135deg, #FF6161, #F53232);
}
.orange {
  background-image: linear-gradient(135deg, #F58166, #FC5B23);
}
.green {
  background-image: linear-gradient(135deg, #48DA97, #19BB65);
}
.blue {
  background-image: linear-gradient(135deg, #4C97FF, #2456EB);
}
.wathet {
  background-image: linear-gradient(135deg, #16B7FD, #2B95E7);
}
.light-orange {
  background-image: linear-gradient(135deg, #FFE36F, #FF7118);
}
.border {
  width: 100%;
  height: 20px;
  background-color: #f1f2f7;
}
</style>
